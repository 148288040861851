export default {
    'Infringement': {
        'icon': '<i class="fa fa-exclamation-circle fa-rep-ans orange"></i>',
        'name': 'Infringement'
    },
    'SeriousInfringement' : {
        'icon': '<i class="fa fa-exclamation-triangle fa-rep-ans red"></i>',
        'name': 'Serious Infringement'
    },
    'Comment': {
        'icon': '<i class="fa fa-comment grey"></i>',
        'name': 'Comment'
    },
    'Letter': {
        'icon': '<i class="fa fa-envelope black"></i>',
        'name': 'Letter',
        'href': `${app.CACHE.URL_ADMIN}tco_drv_let/`,
    },
    'DailyCheck': {
        'icon': '<i class="fa fa-file-alt green"></i>',
        'name': 'Daily Check',
        'href': `${app.CACHE.URL_ADMIN}rep/`,
    },
    'Ferry': {
        'icon': '<i class="fa fa-ferry blue"></i>',
        'name': 'Ferry',
    }
};